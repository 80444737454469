@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1+Code:wght@100..700&display=swap');

:root {
  --color-h1: rgb(158, 195, 172);
  --color-h2: rgb(95, 195, 130);
  --color-h3: #5BAD92;
  --color-posiheader: #272611;
  --color-posicell: #14130e;
  --color-bg: #000402;
  --color-header: #091d14;
  --color-font: #ccc;
  --color-input: #222;
  --color-green: rgb(44, 114, 85);
  --color-grey: #555;
  --color-lightblue: rgb(191, 205, 214);
  --color-copy: rgb(61, 16, 69);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  background-color: var(--color-bg);
  color: var(--color-font);
}

input {
  width: 60px;
  padding: 3px 10px;
  text-align: center;
  border: 0;
  border-radius: 30px;
  font-size: 14px;
  color: var(--color-font);
  background-color: var(--color-input);
}

input:focus {
  outline: none;
}

select {
  font-size: 12px;
  color: var(--color-font);
  background-color: var(--color-input);
}

select:focus {
  outline: none;
}

button {
  background-color: var(--color-input);
  color: var(--color-font);
  font-size: 16px;
  padding: 3px;
}

button:hover {
  transition: 0.3s;
  opacity: 0.8;
}

a {
  color: var(--color-font);
  text-decoration: none;
}

.header {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  display: flex;
  /*vertically align center */
  align-items: center;
  padding: 5px 30px;
  font-size: 24px;
  font-weight: bold;
  background-color: var(--color-header);
  color: var(--color-font);
}

.header span {
  font-family: "M PLUS 1 Code", monospace;
}

.header button {
  margin-left: 60px;
  /*height: 20px;*/
}

.data {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  font-size: 13px;
}

.data span {
  color: var(--color-lightblue);
  font-weight: bold;
}

.about {
  /*align to most right*/
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.about button {
  background: none;
  border: none;
}

.about .policy {
  font-size: 12px;
}

.switch-free-real {
  vertical-align: middle;
}

.switch-flex {
  display: flex;
  align-items: center;
}

.switch-free-real button {
  margin-left: 10px;
  border-radius: 3px;
  border: none;
  padding: 3px 10px;
  font-size: 14px;
  font-style: italic;
  background: none;
  color: var(--color-lightblue);
}

.switch-free-real span {
  color: var(--color-font)
}

.free-or-real {
  font-family: "Noto Serif JP", serif;
  margin-top: 30px;
  margin-left: 30px;
  color: var(--color-lightblue);
  font-size: 16px;
  font-weight: bold;
}

.free-or-real p {
  display: inline-block;
  width: 980px;
  margin: 2px auto;
  text-align: left;
  font-size: 14px;
  font-weight: 400
}

.free-or-real span {
  margin-top: 5px;
  display: block;
  padding: 5px 10px;
  /*border: dashed 1px;
  border-radius: 4px;*/
}

.h0 {
  text-align: center;
  font-weight: bold;
}

.h1 {
  margin-top: 15px;
  padding-left: 15px;
  border-left: 4px solid var(--color-h1);
}

.h2 {
  font-weight: bold;
  margin-top: 15px;
  padding-left: 10px;
  border-left: 5px solid var(--color-h2);
}

.wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.future-wrapper {
  margin-right: 30px;
}

/* .kodai-table {
  table-layout: fixed;
} */

.wrap-form {
  margin-top: 30px;
  display: flex;
}

.caption {
  text-align: left;
  font-style: italic;
}

.form {
  margin: 10px 30px;
}

.select-option {
  width: 180px;
}

.table-wrapper {
  margin-top: 10px;
  position: relative;
  font-size: 12px;
}

.table-scroll-header-fix {
  overflow-y: auto;
  max-height: 400px;
  scrollbar-color: #555 #111;
}

.entry-cell {
  display: flex;
}

/*ATM Hilight*/
#root>div>div.wrap-form>div:nth-child(1)>div.table-wrapper>div>table>tbody>tr:nth-child(41)>td.strike-cell {
  background-color: rgb(29, 112, 1);
}

#root>div>div.wrap-form>div:nth-child(2)>div.table-wrapper>div>table>tbody>tr:nth-child(41)>td.strike-cell {
  background-color: rgb(29, 112, 1);
}

.entry-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.entry-button button {
  margin: 3px 5px;
  padding: 2px;
  border-radius: 3px;
  background-color: #111;
  box-shadow: 0 2px 4px 0 rgba(150, 150, 150, 0.2), 0 2px 5px 0 rgba(150, 150, 150, 0.4);
  /* height: 14px; */
  display: block;
  font-size: 11px;
  border: none;
}

.buy-button {
  color: rgb(179, 90, 90);
}

.sell-button {
  color: rgb(88, 157, 184);
}

.call-cell {
  background-color: rgb(20, 0, 10);
}

.put-cell {
  background-color: rgb(10, 0, 20);
}

.prem-cell {
  text-align: right;
  font-size: 14px;
  font-weight: bold;
}

.strike-cell {
  background-color: rgb(0, 25, 0);
  font-size: 14px;
  text-align: center;
  width: 50px;
}

.greek-cell {
  color: #aaa;
}

table,
th,
td {
  /*avoid border transparent*/
  border: 1px solid #333;
  border-collapse: collapse;
}

th {
  padding: 5px;
}

/*table header*/
.table-scroll-header-fix thead th {
  position: sticky;
  top: 0;
  background-color: #090909;
}

thead>tr:nth-child(2) th {
  /*stick 2nd row of header*/
  top: 18px;
}

.table-scroll-header-fix td {
  padding: 0 5px;
}


.future-input-form {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.future-input-form input {
  margin-right: 10px;
}

/* PositionTable*/

.kodai-table {
  margin-left: 10px;
  margin-top: 5px;
  font-size: 12px;
}

.kodai-table thead th {
  padding: 2px 5px;
  background: var(--color-posiheader);
  font-weight: bold;
}

/* ボディ項目・フッター項目 */
.kodai-table tbody th,
.kodai-table tfoot th {
  background: #faf3db;
}

/* ボディデータ・フッターデータ */
.kodai-table tbody td,
.kodai-table tfoot td {
  background: var(--color-posicell);
  text-align: center;
}

.sim-wrapper {
  margin-top: 45px;
}

.simform-wrapper {
  font-size: 14px;
}

.sim .evalform {
  margin-top: 5px;
  display: flex;
}

.evalform-field {
  margin-top: 5px;
  margin-left: 10px;
  display: inline-block;
}

.date input {
  width: 120px;
}

.evalform button {
  font-size: 14px;
}

.bar-chart {
  width: 680px;
  height: 480px;
  margin: 5px auto;
}

.merged-graph {
  margin-top: 30px;
  text-align: center;
}


.h3 {
  font-weight: bold;
  width: 200px;
  margin: 15px auto;
  border-top: 3px solid var(--color-h3);
  border-bottom: 3px solid var(--color-h3);
  padding-bottom: 3px;
}

.range-selector {
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
}

.freetrade-wrapper {
  margin-left: 20px;
  display: flex;
}

.freetrade-panel {
  margin-top: 30px;
}

.freetrade-panel label {
  margin-left: 5px;
  font-size: 14px;
}

.freetrade-panel button {
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  font-weight: bold;
}

.csv-input {
  font-size: 14px;
  margin-top: 30px;
  padding: 5px 5px;
  border-radius: 5px;
  background-color: var(--color-posiheader);
  width: 150px;
  height: fit-content;
  text-align: center;
}

.freetrade-h1 {
  display: inline-block;
  margin: 5px 0;
  padding: 5px 10px;
  border-top: 2px solid var(--color-h1);
  color: var(--color-font);
  font-weight: bold;
}

.freetrade-inputfield {
  margin-top: 5px;
  margin-left: 30px;
}

.sim-condition button {
  margin: 5px 0 0 0;
  background-color: var(--color-green);
  color: var(--color-font);
}

.ForO {
  font-style: italic;
  padding: 2px 3px;
  border-bottom: 1px solid var(--color-font);
  font-weight: bold;
}

.sim-position form {
  margin-top: 5px;
  margin-left: 15px;
}

.sim-position button {
  margin: 5px 0 0 5px;
  background-color: var(--color-grey);

}

.copyposi button {
  margin-left: 15px;
  border-radius: 3px;
  background-color: var(--color-copy);
  font-size: 10px;
}

.simcon {
  display: inline-block;
}

.freetrade-panel input,
select {
  margin-top: 5px;
  font-size: 14px;
  width: 60px;
}

.dateinput {
  display: inline-block;
}

.dateinput input {
  width: 110px;
}

.freetrade-graph {
  position: fixed;
  top: 240px;
  left: 600px;
}

.loading {
  margin: 20px;
  text-align: center;
  font-size: 36px;
  color: var(--color-lightblue)
}

.loading-dots {
  display: inline-block;
  width: 60px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, var(--color-lightblue) 90%, #0000) 0/calc(100%/3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0)
  }
}

.aboutus {
  margin: 30px;
  color: var(--color-lightblue);
}

.aboutus p {
  margin-left: 20px;
  font-family: "M PLUS 1 Code", monospace;
  font-size: 14px;
}

.aboutus button {
  display: block;
  margin: 0 auto;
  background: none;
  border: none;
}

.footer {
  /*vertically align center */
  align-items: center;
  text-align: center;
  margin-top: 15px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: var(--color-header);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #4c9fe7;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}